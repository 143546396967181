import {  ConfigProvider } from 'antd';
import { Routes, Route} from "react-router-dom";
import Home from "./pages/homePageNew";
import "./App.css";

function App() {
  return ( 
<ConfigProvider
            theme={{
                token: {
                    fontFamily: 'proxima_nova_rgregular',
                    colorPrimary:'#26F8FF',
                    
               

                },
            }}
        >
  <Routes>
  <Route path="/" element={<Home />}/>

</Routes>
  </ConfigProvider>

  )
}

export default App;
