import { useEffect, useState } from 'react'
import { useConnect,useAccount,useContract,useContractWrite,useProvider    } from 'wagmi'
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { Cascader,Row,Button,notification, Input,Steps, Select,Radio,Avatar, Badge, Card, Typography, Space, Col } from 'antd';
import Icon from "@ant-design/icons";
import { DownloadOutlined } from '@ant-design/icons';
import TokenAbi from './contractAbi.json';
import FinityTokenAbi from './finityAbi.json';
import { Alchemy, Network, Utils } from "alchemy-sdk";
import axios from 'axios';
import { ethers } from 'ethers';
import Web3 from 'web3';
import { useNetwork,useSwitchNetwork  } from 'wagmi'
import { FaEthereum } from 'react-icons/fa';
import "./home.css";

const { Meta } = Card;

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const config = {
  apiKey: 'r2J0yAXJyJ4Vsh-fSm4oWIjOYlMJJ5-f',
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

const { Text, Link } = Typography;


const HeartSvg = () => (
  <svg width="20px" height="20px" viewBox="2 -1 15.00 15.00">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 0C7.64839 0 7.78911 0.0659115 7.88411 0.179908L12.8841 6.17991C13.0224 6.34585 13.0385 6.58183 12.924 6.765L7.924 14.765C7.83263 14.9112 7.67239 15 7.5 15C7.3276 15 7.16737 14.9112 7.076 14.765L2.076 6.765C1.96152 6.58183 1.97761 6.34585 2.11589 6.17991L7.11589 0.179908C7.21089 0.0659115 7.35161 0 7.5 0ZM3.24096 6.74213L7.5 5.03852L11.759 6.74213L7.5 13.5566L3.24096 6.74213ZM10.8506 5.30171L7.5 3.96148L4.14943 5.30171L7.5 1.28102L10.8506 5.30171Z" fill="#26F8FF"></path> 
   
    </svg>
);

const HeartIcon = (props) => <Icon component={HeartSvg} {...props} />;

export function Profile({ tokenId, tokenUrl,  amount, activeUnit, activeFeature, mapUrl}) {
  const { connect, connectors, error, isLoading, pendingConnector } =useConnect()
  const { address, isConnected,  connector: activeConnector, } = useAccount();
  const { chain } = useNetwork()
  const { chains, pendingChainId, switchNetwork } =
    useSwitchNetwork()
    const provider = useProvider()
    const [api, contextHolder] = notification.useNotification();

  const [showEthButton, setShowEthButton] = useState(true);
  const [showFinButton, setShowFinButton] = useState(false);

  const [minETH, setMinETH] = useState();
  const [maxFIN, setMaxFIN] = useState();
  const [LoadingScreen, setLoadingScreen] = useState(false);

  const [ethValue, setEthValue] = useState();

  const [crossData, setCrossData] = useState();
  const [finValue, setFinValue] = useState();
  const [switchPay, setSwitchPay] = useState("ETH");

  const [currentStep, setcurrentStep] = useState(0);
  const next = () => {
    setcurrentStep(currentStep + 1);
  };
  const prev = () => {
    setcurrentStep(currentStep - 1);
  };


  const handleSwitchPay =(event) =>{
  
    setSwitchPay(event.target.value);
  }


  
  const steps = [
    {
      title: 'First',
      content: (
      
      <div className='modalMint'>
      
      <Space size={'large'} direction='vertical'>
      <CrossmintPayButton
   
   clientId="02100566-9f7f-4a49-8e9a-e06834d80ff8"
   mintConfig=
   {
     {"type":"erc-721",
     "totalPrice":amount,
     "tokenId":tokenId,
     "count":"1",
     "uri":tokenUrl}}
      whPassThroughArgs={crossData}
   
   className="xmint-btn"
/>

<Button icon={<HeartIcon
     
    />} style={{width:'300px'}} size='large' type='primary'   onClick={()=>next()}>Buy with crypto</Button>
</Space>

    


      </div>),
    },
    {
      title: 'Second',
      content:(<>
      

      <div className='modalMint'>

      <Space  direction='vertical' align='center' style={{marginBottom:"8%"}}>
      <Text style={{color:"#26F8FF", fontSize:"18px", textAlign:"center"}}> 
Connect Wallet
</Text>

      <Text style={{color:"#26F8FF", fontSize:"14px", textAlign:"center"}}> 
Choose how you want to connect with network.
</Text>
</Space>
     
      {connectors.map((connector) => (
      
<>

{isConnected == true && activeConnector.options.appName == connector.options.appName ? (
    <>

<Card onClick={() => next()} style={{ width: 300, background:'#0A0519', border:"1px solid #1AADB2"}} bodyStyle={{textAlign:"justify"}} >
<Badge.Ribbon text="Connected" style={{poition:'absolute', top:'-25px', right:'-25px'}}/>
        <Meta
      
          avatar={<Avatar size="large" src={connector.options.imageIcon} />}
          title={(
            
            <>
             {connector.options.appName}
    {!connector.ready && ' (unsupported)'}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
            </>
           )}
          description={connector.options.appSub}
        />
      </Card>


<br/>
</>
):(
    <>



<Card onClick={() => connection({ connector })} style={{ width: 300}} bodyStyle={{textAlign:"justify"}}>
        <Meta
      
          avatar={<Avatar size="large" src={connector.options.imageIcon} />}
          title={(
            <>
             {connector.options.appName}
    {!connector.ready && ' (unsupported)'}
          {isLoading &&
            connector.id === pendingConnector?.id &&
            ' (connecting)'}
            </>
           )}
          description={connector.options.appSub}
        />
      </Card>
 
 
  
<br/>
</>
)}




</>
      ))}

      </div>
 
 {error && <div>{error.message}</div>}



        <Button type="primary" onClick={()=>prev()}>Previous</Button>
  
       
  
        </>),
    },
    {
      title: 'Last',
      content: (

        <div className='modalMint'>
      
      <Space  direction='vertical' size='large' align='center'>

      <Text style={{color:"#26F8FF", fontSize:"18px", textAlign:"center"}}> Pay with </Text>

<Radio.Group defaultValue="ETH" onChange={event=>{handleSwitchPay(event)}}>
    <Radio.Button value="ETH">Ethereum Only</Radio.Button>
    <Radio.Button value="ETH&FIN">Finity + Ethereum</Radio.Button>
   
</Radio.Group>

      </Space>
    
     

      <>
{switchPay == "ETH"?(<>


  <Row style={{marginTop:"20px", marginLeft:"25%"}}>


<Text style={{color:"#fff", textAlign:"center"}}> Payment Information </Text>
<br/>
<br/>


<Input.Group compact>
  <Input
    style={{
      width: 'calc(100% - 160px)',
     
      color:'white'
    
    }}
    suffix="ETH"
    disabled
   
    value={amount}
  />
 
</Input.Group>

</Row>

<Row align={"middle"} style={{marginTop:"20px", marginLeft:"25%"}}>

<Button  style={{width:'130px'}}type="primary" onClick={()=>(minFunction())}>Pay Now</Button>

</Row>

</>):(<>

  <Row style={{marginTop:"15px"}}>


  <br/>
<br/>


<Text style={{color:"#fff", textAlign:"center"}}> Payment Information </Text>


<Space>
  <Input
    style={{
    color:"white",
 width: 'calc(100% - 25px)'
    }}
    addonBefore="FIN" addonAfter={<Button type="text" onClick={()=>maxFinity()}>MAX</Button>} 
    onChange={event=>handleFinChange(event)}
    value={finValue}
    disabled={showFinButton}
  />
 

 <Button disabled={showFinButton} type="primary" onClick={()=>payFinity()}>Pay Finity</Button>
 
 </Space>





</Row>


<Row style={{marginTop:"15px"}}>
<Space>

  <Input
   
    addonBefore="ETH"
    disabled
    onChange={event=>handleEthChange(event)}
    value={ethValue}
  />
 
  <Button disabled={showEthButton} style={{width:'130px'}}type="primary" onClick={()=>payETH()}>Pay ETH</Button>


  </Space>


</Row>
</>)}
</>
       <Row style={{marginTop:"20px"}}>
        <Button  type="primary" style={{width:'130px'}}onClick={()=>prev()}>Previous</Button>
        </Row>
  
  
        </div>),
    },
  ];




  const network = useSwitchNetwork({
    chainId: 1,
    onError(error) {
      console.log('Error', error)
    },
  })


useEffect(()=>{

const crossmintArg = {
  unitId: activeUnit.id,
  featureId: activeFeature.id,
  mapUrl:mapUrl
};

const whArgsSerialized = JSON.stringify(crossmintArg);
setCrossData(whArgsSerialized);
console.log(whArgsSerialized );

},[activeFeature.id, activeUnit.id])  

  const contract = useContract({
    address: '0x78a92cB8B0A27f7604E04f5FB704F4a7B70Efb36',
    abi: TokenAbi,
    signerOrProvider: provider,

  })

  const finityContract = useContract({
    address: '0x469D2bfAE347bD15FFa580F6a6E3aE623d38da41',
    abi: FinityTokenAbi,
    signerOrProvider: provider,

  })


  

const maxFinity = async()=>{
  if ((await activeConnector.getChainId()) !== 137) {
    if (activeConnector.switchChain) {
      await activeConnector.switchChain(137);
    } else {
      openNotificationFINPAY('error');
    }
  }

  if(chain.id == "137"){
const res = await finityContract.balanceOf(address)
var balNew = ethers.utils.formatUnits(res, 18);
if(balNew > maxFIN){
  setFinValue(maxFIN);
  setEthValue(minETH);
}else{
  setFinValue(balNew);

  var newa = balNew/20000;
  var newb = amount-newa;
  setEthValue(newb);
}

  }
}


  const openNotificationFINPAY = (type, message) => {
 
    api[type]({
      message: 'Transaction Error',
      description:
        message,
    });
  };

  const openNotificationETHPAY = (type) => {
    api[type]({
      message: 'Notification Title',
      description:
        'Please connect to ETH Chain',
    });
  };

  const openNotificationFIN = (type, message) => {
    api[type]({
      message: 'Transaction Error',
      description:
       message
    });
  };


  const openNotificationETH = (type, message) => {
    api[type]({
      message: 'Notification Title',
      description:
        message,
    });
  };


const handleEthChange = (e)=>{
  const value = e.target.value;

  if(value<minETH ){
    openNotificationETH('error');
    setFinValue(maxFIN);
    setEthValue(minETH);
   }else{
     setEthValue(value);
     var newFin = amount-value;
    setFinValue(newFin);
 
   }

}


const handleFinChange = (e)=>{
  const value = e.target.value;

  if(value>maxFIN){
    openNotificationFIN('error', 'Max Finity Error');
   setFinValue(maxFIN);
   setEthValue(minETH);
  }else{
    setFinValue(value);
    var newEth = amount-(value/20000);
   setEthValue(newEth);

  }
}


const payFinity =async()=>{


  if ((await activeConnector.getChainId()) !== 137) {
    if (activeConnector.switchChain) {
      await activeConnector.switchChain(137);
    } else {
      openNotificationFINPAY('error');
    }
  }

  if(chain.id == "137"){
    let amount1 = finValue.toString();
    let NFTprice = Web3.utils.toWei(amount1,'ether');

    const res = await finityContract.balanceOf(address)
     var balNew = ethers.utils.formatUnits(res, 18);
    console.log(balNew);
    console.log(finValue);

    if(balNew >= finValue){
      const tx = await finityContract.connect(await activeConnector.getSigner()).transfer('0x05f4A25071F9755D9D36BCEb80a788122F57EBA9',NFTprice).then (function (result) {
  
  
        setShowFinButton(true);
        setShowEthButton(false);
      
    
    }).catch (function (error){
    
        var message = error.data && error.data.message ;
        console.log(error);
        openNotificationFINPAY('error', error);
       
    });
    }
  else{
    openNotificationFINPAY('error', "Insufficient Fund");
  }

  }






}

const payETH =async()=>{
  
if ((await activeConnector.getChainId()) !== 1) {
  if (activeConnector.switchChain) {
    await activeConnector.switchChain(1);
  } else {
    openNotificationETHPAY('error');
  }
}


  if(chain.id == "1"){
  let amount1 = ethValue.toString();
  let NFTprice = Web3.utils.toWei(amount1,'ether');

  let ethBalance = await alchemy.core.getBalance(address, 'latest');
  ethBalance = Utils.formatEther(ethBalance);

 


if(ethBalance>= ethValue){
  const tx = await contract.connect(await activeConnector.getSigner()).mint(address,tokenId,1,tokenUrl, {value:NFTprice}).then (function (result) {


    checkTransactionStatus(result);
    setShowEthButton(true);

  }).catch (function (error){

      var message = error.data && error.data.message ;
      console.log(error);

  });
  }
  else{
    openNotificationFINPAY('error', "Insufficient Fund");
  }

}

}

  function connection(connector){

    connect( connector );
    
}

useEffect(()=>{
var minEth = amount*0.5;
console.log(minEth);
setMinETH(minEth);
var maxFin = amount*0.5*20000;
setMaxFIN(maxFin);

},[amount])


const minFunction =async()=>{


  setLoadingScreen(true);

console.log(chain.id);

if ((await activeConnector.getChainId()) !== 1) {
  if (activeConnector.switchChain) {
    await activeConnector.switchChain(1);
  } else {
    openNotificationETHPAY('error');
  }
}


if(chain.id == "1"){

    let amount1 = amount.toString();
    let NFTprice = Web3.utils.toWei(amount1,'ether');

    let ethBalance = await alchemy.core.getBalance(address, 'latest');
    ethBalance = Utils.formatEther(ethBalance);

   


if(ethBalance>= amount){
  const tx = await contract.connect(await activeConnector.getSigner()).mint(address,tokenId,1,tokenUrl, {value:NFTprice}).then (async(result)=>  {
    checkTransactionStatus(result);
    
      }).catch (function (error){

        var message = error.data && error.data.message ;
        console.log(error);

    });
}else{
  openNotificationETH('error','Insufficient Fund');
}
   

  }
}


const  checkTransactionStatus=async(result)=>{
  var contractAddressNft= "0x78a92cB8B0A27f7604E04f5FB704F4a7B70Efb36";
var permalink = "https://opensea.io/assets/ethereum/0x78a92cB8B0A27f7604E04f5FB704F4a7B70Efb36/"+tokenId;


  setLoadingScreen(true);
  const txReceipt = await alchemy.core.getTransactionReceipt(
    result.hash
   );
   
   if(txReceipt == null){
    setTimeout(checkTransactionStatus(result), 60);
   }else{
    if(txReceipt.status ==1){
      var data ={
        data:{
        currentOwner:address.toLowerCase(),
        tokenId:tokenId,
        contractAddress:contractAddressNft.toLowerCase(),
        permalink:permalink,
        mapUrl:mapUrl
        }
      }
     await axios.put(`https://mint.infinityvoid.io/gameApi/masterInventory/${activeUnit.id}`, data).then(async()=>{

   

     const dataFeatute={
      properties:{
        name:activeFeature.properties.name,
        unitId:activeFeature.properties.unitId,
        owner:address.toLowerCase(),
        tokenId:tokenId,
        category:activeFeature.properties.category,
        floor:activeFeature.properties.floor,
        contractAddress:contractAddressNft.toLowerCase()

      }
}
     
      await axios.put(`https://mint.infinityvoid.io/gameApi/mapFeature/${activeFeature.id}`, dataFeatute).then(()=>{
        window.location.reload(false);
      })
     })
    }
   }
   

}

  return (
<>
{contextHolder}
      <div >{steps[currentStep].content}</div>
    

    
    </>
  )
}